import React from "react";
import resumeFile from "../documents/CV_BrandonNajera_2023_FullStackWeb&AppDeveloper.pdf";
import presentationFile from "../documents/Fullstack Web Developing Presentation | Brandon Najera.pdf";

const Resume = ({ classicHeader, darkTheme, educationInfo, experienceInfo }) => {

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              Academical Achievements
            </h2>
            {educationInfo.length > 0 &&
              educationInfo.map((eachEducation, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {eachEducation.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {eachEducation.url !== "" ? <a href={eachEducation.url}>{eachEducation.title}</a> : eachEducation.title}
                  </h3>
                  <p className={"text-primary"}>
                    {eachEducation.place}
                  </p>
                  <ul>
                    {eachEducation.desc.map((eachDesc, indexDesc) => (
                      <li
                        key={indexDesc}
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                        {eachDesc}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceInfo.length > 0 &&
              experienceInfo.map((eachExperience, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {eachExperience.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                  {eachExperience.url !== "" ? <a href={eachExperience.url}>{eachExperience.title}</a> : eachExperience.title}
                  </h3>
                  <p className={"text-primary"}>
                    {eachExperience.place}
                  </p>
                  <ul>
                    {eachExperience.desc.map((eachDesc, indexDesc) => (
                      <li
                        key={indexDesc}
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                        {eachDesc}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
        </div>

        <div className="text-center mt-5">
          <a href={resumeFile} download className="btn btn-primary rounded-pill" style={{margin: '5px'}}>
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
          <a href={presentationFile} download className="btn  rounded-pill" style={{margin: '5px'}}>
            Download Presentation
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
