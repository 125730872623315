import React from "react";

const AboutUs = ({ classicHeader, darkTheme, aboutInfo, skills, contact }) => {

  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Get to Know Me
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              {aboutInfo.details.title.map((eachWord, index) => (
                eachWord.important ? <span className="text-primary">{eachWord.word}</span> : <span className="">{eachWord.word}</span>
              ))}
            </h2>
            {aboutInfo.details.description.map((eachParagraph, index) => (
              <p className={darkTheme ? "text-white-50" : ""}>
                {eachParagraph}
              </p>
              ))}
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li index={0}>
                  <span className="fw-600 me-2">Name:</span>
                  <p className="text-2">{aboutInfo.details.profileDetails.name}</p>
                  <span className="fw-600 me-2">Age:</span>24
                  <br></br>
                  <br></br>
                  <span className="fw-600 me-2">From:</span>{aboutInfo.details.profileDetails.place}
                </li>
                <li index={1}>
                  <span className="fw-600 me-2">About:</span>
                  <p className="text-2">{aboutInfo.details.profileDetails.briefDescription}</p>
                </li>
                <li index={2}>
                  <span className="fw-600 me-2">Contact:</span>
                  <p>
                    <a className="text-2" href={"mailto:" + contact.details.mail}>{contact.details.mail}</a>
                    <br></br>
                    <a className="text-2" href={"tel:+" + contact.details.tel.number}>{contact.details.tel.text}</a>
                  </p>
                </li>
              </ul>
              {/* <a href={resumeFile} download className="btn btn-primary rounded-pill">
                Download CV
              </a> */}
            </div>
          </div>
        </div>

        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((eachSkill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {eachSkill.name}{" "}
                  <span className="float-end">{eachSkill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: eachSkill.percent + "%" }}
                    aria-valuenow={eachSkill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>

        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >

          {/* <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>10</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>250</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>650</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>38</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Get Awards
                </p>
              </div>
            </div>
          </div> */}

        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
