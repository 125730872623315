import "./App.scss";
import Header from "./components/Header";
import Home from "./components/Home";
import AboutUs from "./components/About";
import Services from "./components/Services";
import Resume from "./components/Resume";
import Portfolio from "./components/Portfolio";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import ClassicHeader from "./components/ClassicHeader";
import { commonConfig } from "./config/commonConfig";
import TermsAndConditions from "./components/TermsAndConditions";
import Disclaimer from "./components/Disclaimer";
import PreLoader from "./components/Preloader";
import { Tooltip } from "./components/Tooltip";

// Librerias de prueba
import axios from 'axios';
import env from "react-dotenv";

function App() {
  const classicHeader = commonConfig.classicHeader;
  const darkTheme = commonConfig.darkTheme;
  const lightTheme = commonConfig.lightTheme;

  const [scrollTopVisible, setScrollTopVisible] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  // Codigo de prueba
  const [about, setAbout] = useState();
  const [contact, setContact] = useState();
  const [skills, setSkills] = useState([]);
  const [footer, setFooter] = useState();
  const [homeSection, setHome] = useState();
  const [projects, setProjects] = useState([]);
  const [educations, setEducations] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [services, setServices] = useState();
  const [testimonials, setTestimonials] = useState([]);


  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  useEffect( () => {

    const fetchData = async () => {
  
      // Till the data is fetch using API 
      // the Loading page will show.
      setisLoading(true);

      // Await make wait until that 
      // promise settles and return its result
      const responseAbout = await axios.get(env.ABOUT_CONTENT_DB);
      const responseSkills = await axios.get(env.ABOUT_SKILLS_DB);
      const responseContact = await axios.get(env.CONTACT_DB);
      const responseFooter = await axios.get(env.FOOTER_DB);
      const responseHome = await axios.get(env.HOME_CONTENT_DB);
      const responsePortfolio = await axios.get(env.PORTOFOLIO_DB);
      const responseEducation = await axios.get(env.RESUME_EDUCATION_DB);
      const responseExperience = await axios.get(env.RESUME_EXPERIENCE_DB);
      const responseServices = await axios.get(env.SERVICE_CONTENT_DB);
      const responseTestimonial = await axios.get(env.TESTIMONIAL_DB);

      // After fetching data stored it in posts state.
      setAbout(responseAbout.data);
      setSkills(responseSkills.data);
      setContact(responseContact.data);
      setFooter(responseFooter.data);
      setHome(responseHome.data);
      setProjects(responsePortfolio.data);
      setEducations(responseEducation.data);
      setExperiences(responseExperience.data);
      setServices(responseServices.data);
      setTestimonials(responseTestimonial.data);

      // Closed the loading page
      setisLoading(false);

  }
    
    fetchData();
    console.log(homeSection); 
    
  }, []);

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (isLoading ? <PreLoader></PreLoader> :
    <>
      <div
        style={{ position: "relative" }}
        className={classicHeader ? "" : "side-header"}
      >
        {/* {isLoading && <PreLoader></PreLoader>} */}

        <div id="main-wrapper">
          {classicHeader ? (
            <ClassicHeader 
              handleNavClick={handleNavClick}
              contact={contact}
              ></ClassicHeader>
          ) : (
            <Header
              handleNavClick={handleNavClick}
              contact={contact}
            ></Header>
          )}

          <div id="content" role="main">
            <Home
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              handleNavClick={handleNavClick}
              homeInfo={homeSection}
              contact={contact}
            ></Home>
            <AboutUs
              classicHeader={classicHeader}
              darkTheme={lightTheme}
              aboutInfo={about}
              skills={skills}
              contact={contact}
            ></AboutUs>
            <Services
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              servicesInfo={services}
            ></Services>
            <Resume
              classicHeader={classicHeader}
              darkTheme={lightTheme}
              educationInfo={educations}
              experienceInfo={experiences}
            ></Resume>
            <Portfolio
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              portfolioInfo={projects}
            ></Portfolio>
            <Testimonials
              classicHeader={classicHeader}
              darkTheme={lightTheme}
              testimonialInfo={testimonials}
            ></Testimonials>
            <Contact
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              contact={contact}
            ></Contact>
          </div>
          <Footer
            classicHeader={classicHeader}
            darkTheme={lightTheme}
            handleNavClick={handleNavClick}
            footerInfo={footer}
          ></Footer>
        </div>
        {/* back to top */}
        <Tooltip text="Back to Top" placement="left">
          <span
            id="back-to-top"
            className="rounded-circle"
            style={{ display: scrollTopVisible ? "inline" : "none" }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <i className="fa fa-chevron-up"></i>
          </span>
        </Tooltip>

        <TermsAndConditions darkTheme={darkTheme}></TermsAndConditions>
        <Disclaimer darkTheme={darkTheme}></Disclaimer>
      </div>
    </>
  );
}

export default App;
